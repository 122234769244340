'use client';

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { signIn, signOut } from '@aws-amplify/auth';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, ConfigProvider, Divider, Input } from 'antd';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';

import { theme } from '@/common/theme';
import { useNotification } from '@/providers/NotificationProvider';
import { LoginSchema } from '@/schema/auth/loginSchema';

type LoginType = {
  email: string;
  password: string;
};

const LoginPage = () => {
  const router = useRouter();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginType>({
    criteriaMode: 'all',
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
    resolver: yupResolver(LoginSchema) as Resolver<LoginType>,
  });

  const handleLogin = async (data: LoginType) => {
    setIsLoading(true);
    try {
      await signOut();
    } catch (e) {
      console.error('Sign out error:', e);
    }

    try {
      const { nextStep } = await signIn({
        password: data.password,
        username: data.email,
      });

      // 仮パスワードでログインした場合
      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        router.push(`/auth/newpassword`);
        return;
      }

      router.push('/');
    } catch (error) {
      console.log(error);
      setErrorMessage('メールまたはパスワードが正しくありません');
      showNotification('error', 'エラー', 'ログインに失敗しました');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <title>アカウントにログイン - LIFTI office（リフティオフィス）</title>
      <ConfigProvider theme={theme}>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="flex flex-col items-center">
              <Image
                width={180}
                height={90}
                className="h-12 w-auto"
                src="/images/logo/office-logo.svg"
                alt="logo"
              />
              <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                アカウントにログイン
              </h2>
            </div>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="-mt-6 mb-6">
              {errorMessage && <Alert type="error" message={errorMessage} banner />}
            </div>
            <form className="space-y-6" onSubmit={handleSubmit(handleLogin)}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  メールアドレス
                </label>
                <div className="relative mt-2">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="email"
                        id="email"
                        {...field}
                        size="large"
                        status={errors.email && 'error'}
                      />
                    )}
                  />
                  {errors.email && errors.email.message && (
                    <>
                      <div className="pointer-events-none absolute inset-y-0 bottom-7 right-0 flex items-center pr-3">
                        <ExclamationCircleIcon className="size-5 text-red-500" aria-hidden="true" />
                      </div>
                    </>
                  )}
                  <p className="mt-2 text-sm text-red-600" id="company-error">
                    {errors.email?.message}
                  </p>
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    パスワード
                  </label>
                  <div className="text-sm">
                    <a
                      href="/auth/forgotpassword"
                      className="text-accent  no-underline hover:text-accent-hover"
                    >
                      パスワードを忘れた場合
                    </a>
                  </div>
                </div>
                <div className="relative mt-2">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <Input.Password
                        size="large"
                        {...field}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        status={errors.password && 'error'}
                      />
                    )}
                  />
                  <p className="mt-2 text-sm text-red-600" id="company-error">
                    {errors.password?.message}
                  </p>
                </div>
              </div>

              <div>
                <Divider />
                <Button
                  htmlType="submit"
                  type="primary"
                  className="w-full"
                  size="large"
                  loading={isLoading}
                >
                  ログイン
                </Button>
              </div>
            </form>
          </div>
        </div>
      </ConfigProvider>
    </>
  );
};

export default LoginPage;
